// Importing necessary components and hooks from Backstage and Material-UI libraries
import React from 'react';
import { Card, CardContent, Typography, Checkbox } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';

// SimpleCard functional component
export function CustomCard() {
  // Hook to access the entity from the Backstage catalog
  const { entity } = useEntity();

  // Extracting name and description from the entity metadata
  const customFields = entity.metadata.customFields || {};
  // @ts-ignore
  const vp = customFields.vp || 'No vice presidence set';
  // @ts-ignore
  const applicationType = customFields.applicationType || 'No application type set';
  // @ts-ignore
  const costingGroup =   customFields.costingGroup || 'No costing group set';
  // @ts-ignore
  const hostingType =     customFields.hostingType || 'No hosting type set';
  // @ts-ignore
  const critical =     customFields.critical || 'No critical set';
  // @ts-ignore
  const criticalMotive =     customFields.criticalMotive || 'No critical motive set';
  // @ts-ignore
  const observabilidade =     customFields.observabilidade || 'No observabilidade set';
  // @ts-ignore
  const sox =     customFields.sox || false;

  // Custom data for demonstration, here it's static but it can be dynamic
  const customData = {
    lastUpdated: '2024-01-11', // Date of last update
    status: 'Hyperactive' // Status of the entity
  };

  // JSX for rendering the component
  return (
    <Card>
      <CardContent>
        {/* Title for the card */}
        <Typography variant="h5">Dados adicionais</Typography>
        {/* Displaying the name and description */}
        <Typography color="textSecondary" gutterBottom>
          Vice Presidência: {vp}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Tipo de sistema : {applicationType}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Grupo de custo : {costingGroup}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Tipo de hosting : {applicationType}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          É Critico :
          <Checkbox checked={critical} title='É Critico' color="secondary"/>
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Porque é Critico : {criticalMotive}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Está com observbilidade :
          <Checkbox checked={observabilidade} title='É Observável' color="secondary"/>
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          É Sox :
          <Checkbox checked={sox} title='É Sox' color="secondary"/>
        </Typography>
        
        
        <Typography variant="body2">
          Last Updated: {customData.lastUpdated}
        </Typography>
      </CardContent>
    </Card>
  );
}
