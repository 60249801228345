import { createBaseThemeOptions, createUnifiedTheme, genPageTheme, palettes } from "@backstage/theme";

export const ipirangaTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
        palette: {
            ...palettes.light,
            background:{
                default: '#ffffff',
                paper: '#ffffff',
            },
            text: {
                primary: '#FFA500',
                secondary: '#1706E1'
            },
            primary: {
                main: '#0f62fe',
                dark: '#002d9c'
            },
            secondary:{
                main: '#393939'
            },
            navigation: {
                background: '#f1c21b',
                indicator: '#ffffff',
                color: '#ffffff',
                selectedColor: '#393939',
                navItem: {
                    hoverBackground: 'rgba(116,118,121,0.6)',
                  }
            },
        },
    }),
    pageTheme: {
        home: genPageTheme({
            colors: ['#f1c21b'],
            shape: 'none'
        })
    }
});