import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#0043ce',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="144" height="40" viewBox="0 0 144 40" fill="none"
    >   
        <path className={classes.path} d="M31.5108 6.01918L23.6725 13.5731C23.3094 13.9363 22.9873 14.0562 22.703 14.0562C22.2165 14.0562 21.8945 13.7342 21.7335 13.2066L19.5512 5.77253C19.5101 5.61151 19.469 5.4505 19.469 5.28606C19.469 4.7208 19.8321 4.31655 20.4796 4.31655H31.1442C31.5896 4.31655 31.9938 4.63858 31.9938 5.12504C31.9938 5.36828 31.8328 5.6903 31.5108 6.01576M36.074 0.404248C35.0634 0.284344 26.9442 0 23.0661 0C15.1901 0 9.33196 0.0411099 4.76533 4.80644C0 9.77732 0 16.3207 0 26.903V32.7612C0 33.6485 0.486468 34.2549 1.45255 34.2549C2.01781 34.2549 2.46317 34.0939 3.27167 33.2854L20.3186 16.8037C20.6029 16.5194 20.9661 16.3583 21.247 16.3583C21.8123 16.3583 22.2987 16.8037 22.2987 17.4478V33.0421C22.2987 33.7684 22.7441 34.2549 23.4293 34.2549H23.9534C26.0123 34.2549 30.0137 33.4053 33.3642 29.8527C37.4443 25.5704 37.6465 20.8839 37.6465 11.9973V2.02124C37.6465 1.13052 37.0401 0.527578 36.0706 0.404248" fill="#0043BC"/>
        <path className={classes.path} d="M50.2329 16.3613H47.5128C47.2113 16.3613 47.0605 16.5121 47.0605 16.8135V33.8433C47.0605 34.1448 47.2113 34.2956 47.5128 34.2956H50.2329C50.5343 34.2956 50.6851 34.1448 50.6851 33.8433V16.8135C50.6851 16.5121 50.5343 16.3613 50.2329 16.3613Z" fill="#0043BC"/>
        <path className={classes.path} d="M58.8079 31.7676C58.2426 31.7676 57.7116 31.6923 57.2217 31.5415V28.6707C57.2217 25.2723 58.8079 23.8368 60.6579 23.8368C62.5078 23.8368 63.378 25.0838 63.378 27.1222C63.378 30.1061 61.3396 31.7676 58.8079 31.7676M61.4903 20.8906C59.1882 20.8906 57.5643 22.0828 56.8449 23.7067L56.355 21.7231C56.242 21.2709 56.0912 21.1578 55.7898 21.1578H54.2413C53.9398 21.1578 53.7891 21.3086 53.7891 21.6101V39.2839C53.7891 39.51 53.9775 39.6984 54.2036 39.6984H56.8106C57.0368 39.6984 57.2252 39.51 57.2252 39.2839V34.4124C57.7527 34.5254 58.4345 34.6008 58.8867 34.6008C63.7959 34.6008 66.9682 31.5038 66.9682 27.0468C66.9682 23.3469 64.7791 20.8906 61.4938 20.8906" fill="#0043BC"/>
        <path className={classes.path} d="M108.009 20.8906C105.933 20.8906 104.234 21.8362 103.364 23.5354L102.912 21.7231C102.799 21.2709 102.648 21.1578 102.346 21.1578H100.798C100.496 21.1578 100.346 21.3086 100.346 21.6101V33.8437C100.346 34.1452 100.496 34.2959 100.798 34.2959H103.367C103.669 34.2959 103.819 34.1452 103.819 33.8437V27.5744C103.819 25.1969 105.33 23.9122 107.105 23.9122C108.503 23.9122 109.52 24.8201 109.52 26.3651V33.8437C109.52 34.1452 109.671 34.2959 109.972 34.2959H112.542C112.843 34.2959 112.994 34.1452 112.994 33.8437V26.1767C112.994 22.8913 111.182 20.8906 108.009 20.8906" fill="#0043BC"/>
        <path className={classes.path} d="M140.448 26.5193C140.448 29.9177 139.051 31.6546 137.012 31.6546C135.313 31.6546 134.142 30.483 134.142 28.3692C134.142 25.6491 136.105 23.7238 138.825 23.7238C139.352 23.7238 139.959 23.7992 140.448 23.9499V26.5193V26.5193ZM143.508 21.7985C142.638 21.4216 140.825 20.8906 138.9 20.8906C133.576 20.8906 130.555 24.3267 130.555 28.4446C130.555 32.1445 132.97 34.6008 136.218 34.6008C138.438 34.6008 140.219 33.4668 140.932 31.634L141.315 33.3538C141.504 34.1828 141.617 34.2993 142.148 34.2993H143.432C143.734 34.2993 143.885 34.1486 143.885 33.8471V22.4048C143.885 22.1411 143.772 21.9149 143.508 21.8019" fill="#0043BC"/>
        <path className={classes.path} d="M121.603 31.2773C119.866 31.2773 118.694 29.9926 118.694 28.0296C118.694 25.5356 120.62 23.7233 123.377 23.7233C123.905 23.7233 124.511 23.7987 125.001 23.9494V26.2516C125.001 29.6123 123.566 31.2738 121.603 31.2738M128.06 21.7946C127.42 21.4554 125.303 20.8867 123.189 20.8867C118.054 20.8867 115.107 24.0967 115.107 28.1358C115.107 32.1748 117.865 33.9528 120.808 33.9528C122.778 33.9528 124.237 33.0861 124.96 31.798V33.2368C124.96 35.7274 123.412 36.899 120.883 36.899C119.373 36.899 118.088 36.4845 116.769 35.9946C116.505 35.8816 116.166 35.9193 116.166 36.2961V38.1461C116.166 38.4852 116.279 38.7867 116.731 39.0162C117.978 39.6568 119.715 39.9994 121.377 39.9994C126.474 39.9994 128.437 36.9778 128.437 33.0895V22.4044C128.437 22.1783 128.324 21.9522 128.06 21.8014" fill="#0043BC"/>
        <path className={classes.path} d="M94.3411 26.5193C94.3411 29.9177 92.9433 31.6546 90.905 31.6546C89.2057 31.6546 88.0341 30.483 88.0341 28.3692C88.0341 25.6491 89.9971 23.7238 92.7172 23.7238C93.2448 23.7238 93.8512 23.7992 94.3411 23.9499V26.5193V26.5193ZM97.4003 21.7985C96.5302 21.4216 94.7179 20.8906 92.7926 20.8906C87.4688 20.8906 84.4473 24.3267 84.4473 28.4446C84.4473 32.1445 86.8625 34.6008 90.1102 34.6008C92.3301 34.6008 94.1115 33.4668 94.8241 31.634L95.2078 33.3538C95.3962 34.1828 95.5093 34.2993 96.0368 34.2993H97.3969C97.6607 34.2993 97.7737 34.1109 97.7737 33.9225V22.4048C97.7737 22.1411 97.6607 21.9149 97.3969 21.8019" fill="#0043BC"/>
        <path className={classes.path} d="M83.5013 21.0414C83.2375 20.9283 82.7065 20.8906 82.2919 20.8906C80.6304 20.8906 78.7428 21.9115 78.1398 23.7238L77.6499 21.7231C77.5369 21.2709 77.3861 21.1578 77.0847 21.1578H75.5362C75.2347 21.1578 75.084 21.3086 75.084 21.6101V33.8437C75.084 34.1452 75.2347 34.2959 75.5362 34.2959H78.1056C78.407 34.2959 78.5578 34.1452 78.5578 33.8437V27.917C78.5578 25.3853 79.6917 24.1417 81.8431 24.1417C82.22 24.1417 82.5591 24.1794 83.0901 24.2548C83.6177 24.3302 83.8438 24.2925 83.8438 23.8403V21.6888C83.8438 21.3874 83.8438 21.1989 83.5047 21.0482" fill="#0043BC"/>
        <path className={classes.path} d="M72.1368 21.1582H69.5674C69.266 21.1582 69.1152 21.3089 69.1152 21.6104V33.8441C69.1152 34.1455 69.266 34.2963 69.5674 34.2963H72.1368C72.4383 34.2963 72.589 34.1455 72.589 33.8441V21.6104C72.589 21.3089 72.4383 21.1582 72.1368 21.1582Z" fill="#0043BC"/>
        
    </svg>
  );
};

export default LogoFull;
