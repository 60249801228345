import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#0043ce',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="160.000000pt" height="160.000000pt" viewBox="0 0 160.000000 160.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
      <path className={classes.path} d="M642 1164 c-72 -19 -106 -38 -154 -86 -86 -86 -108 -180 -108 -464 0
      -166 2 -193 16 -198 9 -3 18 -6 21 -6 2 0 101 93 219 206 143 138 219 204 229
      200 13 -4 15 -36 15 -200 0 -224 -4 -216 95 -191 73 20 140 67 182 129 53 78
      58 107 58 371 l0 239 -25 7 c-50 14 -492 9 -548 -7z m454 -85 c10 -17 -186
      -210 -210 -207 -14 2 -25 25 -47 98 -16 52 -26 101 -24 108 6 16 270 17 281 1z"/>
      </g>
    </svg>
  );
};

export default LogoIcon;

